/* Overall Layout */
.dashboard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: #18191a;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Content Layout */
.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  gap: 20px;
  margin: 20px auto;
  padding: 0 20px;
}

/* Container Layouts */
/* Container Layouts */
.metrics-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  padding-bottom: 0;
  padding-top: 0;
}

.user-container,
.spin-time,
.chart-container,
.lists-container {
  background-color: #242526;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.user-container,
.spin-time {
  width: 100%;
  margin: 0;
  padding-top: 0;
}

.chart-container {
  width: 60%;
  max-width: 1500px;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: visible;
}

.lists-container {
  width: 20%;
  height: 87vh;
}


.metrics-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 0;
}

.user-container,
.spin-time {
  width: 100%;
  margin: 0;
  padding-top: 0;
}

.chart-container {
  width: 60%;
  max-width: 1500px;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: visible;
}

.lists-container {
  width: 20%;
  height: 87vh;
}

/* Header and Button Styling */
.header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  position: sticky;
}

.header-buttons button {
  z-index: 10;
}

.header-buttons h3 {
  text-align: center;
  font-size: 1.75em;
  color: #b9b9b9;
  margin: 5px 0;
}

.header-buttons img {
  height: 20px;
  width: 20px;
}

/* Button Styles */
.lists-container button,
.chart-container button {
  background-color: #242526;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #474747;
}

button:focus {
  outline: none;
}

/* Scrollbar Styling */
.lists-container::-webkit-scrollbar {
  width: 8px;
}

.lists-container::-webkit-scrollbar-track {
  background: #242526;
}

.lists-container::-webkit-scrollbar-thumb {
  background-color: #474747;
  border-radius: 10px;
  border: 2px solid #242526;
}

.lists-container {
  scrollbar-width: thin;
  scrollbar-color: #474747 #242526;
}

/* Animation Classes */
.fade-enter,
.slide-left-enter,
.slide-right-enter {
  opacity: 1;
}

.fade-enter,
.slide-left-enter,
.slide-right-enter {
  transform: translateX(100%);
}

.fade-enter-active,
.slide-left-enter-active,
.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit,
.slide-left-exit,
.slide-right-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-exit-active,
.slide-left-exit-active,
.slide-right-exit-active {
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

/* Mobile View */
@media (max-width: 1000px) {
  .content {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .lists-container,
  .metrics-container,
  .chart-container {
    width: 90%;
    max-width: 100%;
  }

  .chart-container {
    order: 2;
  }

  .lists-container {
    overflow-y: visible;
    order: 3;
    height: auto;
    max-height: 100%;
  }

  .metrics-container {
    order: 1;
  }
}

/* Desktop View - Sticky Sidebar */
@media (min-width: 1001px) {
  .lists-container {
    overflow-y: auto;
    position: sticky;
    top: 0;
  }
}