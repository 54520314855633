.bar-chart-container {
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.bar {
    fill: rgb(207, 31, 0);
}

.bar:hover {
    fill: rgb(207, 31, 0);
}

.label {
    font-size: 12px;
    fill: white;
}

.bar-chart-container svg {
    width: 100%;
    height: auto;
}

.bar-chart-container h2 {
    text-align: center;
}

.bar-chart-container p {
    text-align: center;
}