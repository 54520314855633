/* For the track text next to the image */
.tracks-container h2 {
  text-align: center;
  font-size: 1.5em;
  color: #e0e0e0;
  margin-bottom: 5px;
  margin-top: 5px;
}

.track-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.track-link {
  display: flex;
  align-items: center;
}

.tracks-container ol {
  padding-inline-start: 20px;
}

.list-item {
  display: flex;
  align-items: left;
  margin-bottom: 10px;
  color: #e0e0e0;
}

.track-image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 5px;
}

.track-text p {
  margin: 0;
  color: #e0e0e0;
  font-size: 14px;
  line-height: 1.4;
}

.track-name {
  font-weight: bold;
}