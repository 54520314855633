/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.app {
    font-family: Roboto;
    height: 100%;
}

h1,
h2,
h3,
h4,
li,
p {

    color: #e0e0e0;
}