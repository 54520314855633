/* /components/LoginForm.css */

/* Center the form on the page */
.auth-container {
  background: #242526;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 300px;
  max-width: 90%;
  margin: 50px auto 0;
}

.auth-container h2 {
  margin: 0 0 25px;
  color: #cfcfcf;
}

/* Style the input fields */
.auth-container input {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  color: #0e0e0e;
  background-color: #cacaca;
  border: 1px solid #989898;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  margin: 10px auto;
}

/* Make placeholder text black */
.auth-container input::placeholder {
  color: #0e0e0e;
  /* Black color for placeholder */
}

.auth-container input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Password container with icon */
.password-group {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.password-container input {
  border-radius: 4px;
}

.toggle-password-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #999;
  transition: color 0.3s;
}

.toggle-password-icon:hover {
  color: #333;
}

/* Style the buttons */
.auth-container button {
  width: 95%;
  padding: 10px;
  margin: 10px auto;
  border: none;
  border-radius: 5px;
  background: #1e1e1e;
  color: #cfcfcf;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  display: block;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  /* Ensure it's behind the form */
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  width: 100%;
  opacity: 0.3;
  border-radius: 15px;
}

.auth-container button:hover {
  background: #0056b3;
}

.auth-container button:disabled {
  background: #1e1e1e;
  cursor: not-allowed;
}

/* Style error and response messages */
.auth-container p {
  font-size: 14px;
  margin-top: 10px;
}

.auth-container .error {
  color: #ff4d4d;
}

.auth-container .success {
  color: #28a745;
}

/* Resume link styles */
.resume-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cfcfcf;
}

.resume-link a {
  color: #cfcfcf;
}

@media (max-width: 768px) {
  .image-container {
    justify-content: center;
    align-items: flex-end;
    /* Move image to bottom */
    margin-bottom: 20px;
    /* 20px from bottom */
  }

  .background-image {
    width: 100%;
    /* Adjust the image size if needed */
  }
}