  /* Now Playing container */
  .now-listening-container h2 {
    text-align: left;
    font-size: 1.5em;
    color: #e0e0e0;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .listening-container {
    margin-top: 15px;
    display: flex;
  }

  /* Headings in each section */
  .listening-container h3 {
    text-align: center;
    font-size: 1.5em;
    color: #e0e0e0;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .jams-link {
    color: #e0e0e0;
  }


  .listening-container img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    /* Space between image and text */
    animation: spin 5s linear infinite;
    /* Animation for rotating the album art */
  }

  .listening-text p {
    margin: 0;
    color: #e0e0e0;
    font-size: 14px;
    line-height: 1.4;
  }

  .listening-text p:first-child {
    font-weight: bold;
    /* Make the song title bold */
  }

  /* Animation for rotating the album art */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {}