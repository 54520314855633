@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
html, body, #root {
    height: 100vh;
}
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #18191a;
}

.resume-container {
  max-width: 95%;
  /* Ensure full width */
  width: 900px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 30px;
  padding-top: 10px;
  margin-top: 15px;
  background-color: #d6d6d6;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  /* Include padding in width */
}

.resume-container p,
.resume-container h1,
.resume-container h2,
.resume-container h3,
.resume-container h4,
.resume-container li {
  color: black;
}



header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.titles {
  text-align: left;
  flex: 2 1;
  /* Allow titles to take up remaining space */
}

.socials {
  text-align: center;
  flex: 1 1;
  /* Allow socials to take up remaining space */

}

.socials img {
  height: 50px;
  width: 50px;
  margin: 7px;
}

.git-logo {
  margin-left: 0px;
}



header h1 {
  margin-bottom: 0;
  font-size: 2em;
}

header p {
  margin-top: 5px;
  color: gray;
}

.resume-section {
  margin-bottom: 30px;
}

.resume-section h2 {
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
  margin-bottom: 15px;
  font-size: 1.5em;
  cursor: pointer;
  color: solid #333;
  display: flex;
  align-items: center;
}

.resume-section h2:hover {
  text-decoration: underline;
}

.resume-section h2 .fa-icon {
  margin-left: 8px;
}

.section-icon {
  font-size: 16px;
  margin-left: 8px;
}

.resume-item {
  margin-bottom: 20px;
}

.resume-item h3 {
  margin-bottom: 5px;
  font-size: 1.3em;
  display: flex;
  align-items: center;
}

.resume-item h3 img {
  margin-right: 10px;
}

.resume-item p {
  margin-bottom: 5px;
  color: gray;
  font-size: 0.9em;
}

.skills-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 15px;
}

.skills-list li {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  background: #eee;
  padding: 5px 10px;
  border-radius: 5px;
}

.project-title {
  margin-bottom: 0px;
  text-decoration: underline;
}

.project-info {
  margin-top: 5px;
}

a {
  color: #1a0dab;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.linkedin-logo {
  width: 60px;
  height: 60px;
  vertical-align: middle;
}

.git-logo {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.company-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  vertical-align: middle;
}

/* Responsive Styles */
@media (max-width: 768px) {


  .titles h1 {
    font-size: 1.5em;
  }

  .titles h2 {
    font-size: 1.3em;
  }

  .resume-item h3 {
    font-size: 1.2em;
  }

  .resume-item p {
    font-size: 0.85em;
  }

  .skills-list {
    font-size: 0.85em;
    margin-right: 8px;
  }

  .socials img {
    height: 40px;
    margin: 7px;
    width: 40px;
  }
}

@media (max-width: 480px) {
  header h1 {
    font-size: 1.5em;
  }

  header h2 {
    font-size: 1.2em;
  }

  header p {
    font-size: 0.9em;
  }

  .resume-section h2 {
    font-size: 1em;
  }

  .resume-item h3 {
    font-size: 1em;
  }

  .resume-item p {
    font-size: 0.8em;
  }

  .skills-list {
    font-size: 0.8em;
    margin-right: 5px;
    padding: 3px 8px;
  }

  .socials {
    display: flex;
    justify-content: center;
    /* Centers horizontally */
    align-items: center;
    /* Centers vertically */
    flex-direction: row;
    /* Makes sure the items stay in a row */
    flex: 1 1;
  }

  .socials img {
    height: 40px;
    margin: 5px;
    width: 40px;
    display: block;
  }
}

@media (max-width: 375px) {

  .titles h1 {
    font-size: 1em;
  }

  .titles h2 {
    font-size: .8em;
  }

  .socials img {
    height: 30px;
    width: 30px;
    margin: 5px;
  }
}

@media (max-width: 320px) {


  .titles h1 {
    font-size: 1em;
  }

  .titles h2 {
    font-size: .8em;
  }

  .socials img {
    height: 30px;
    width: 30px;
    margin: 3px;
  }
}
/* /components/LoginForm.css */

/* Center the form on the page */
.auth-container {
  background: #242526;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 300px;
  max-width: 90%;
  margin: 50px auto 0;
}

.auth-container h2 {
  margin: 0 0 25px;
  color: #cfcfcf;
}

/* Style the input fields */
.auth-container input {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  color: #0e0e0e;
  background-color: #cacaca;
  border: 1px solid #989898;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  margin: 10px auto;
}

/* Make placeholder text black */
.auth-container input::placeholder {
  color: #0e0e0e;
  /* Black color for placeholder */
}

.auth-container input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Password container with icon */
.password-group {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.password-container input {
  border-radius: 4px;
}

.toggle-password-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #999;
  transition: color 0.3s;
}

.toggle-password-icon:hover {
  color: #333;
}

/* Style the buttons */
.auth-container button {
  width: 95%;
  padding: 10px;
  margin: 10px auto;
  border: none;
  border-radius: 5px;
  background: #1e1e1e;
  color: #cfcfcf;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  display: block;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  /* Ensure it's behind the form */
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  width: 100%;
  opacity: 0.3;
  border-radius: 15px;
}

.auth-container button:hover {
  background: #0056b3;
}

.auth-container button:disabled {
  background: #1e1e1e;
  cursor: not-allowed;
}

/* Style error and response messages */
.auth-container p {
  font-size: 14px;
  margin-top: 10px;
}

.auth-container .error {
  color: #ff4d4d;
}

.auth-container .success {
  color: #28a745;
}

/* Resume link styles */
.resume-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cfcfcf;
}

.resume-link a {
  color: #cfcfcf;
}

@media (max-width: 768px) {
  .image-container {
    justify-content: center;
    align-items: flex-end;
    /* Move image to bottom */
    margin-bottom: 20px;
    /* 20px from bottom */
  }

  .background-image {
    width: 100%;
    /* Adjust the image size if needed */
  }
}
  /* Now Playing container */
  .now-listening-container h2 {
    text-align: left;
    font-size: 1.5em;
    color: #e0e0e0;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .listening-container {
    margin-top: 15px;
    display: flex;
  }

  /* Headings in each section */
  .listening-container h3 {
    text-align: center;
    font-size: 1.5em;
    color: #e0e0e0;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .jams-link {
    color: #e0e0e0;
  }


  .listening-container img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    /* Space between image and text */
    animation: spin 5s linear infinite;
    /* Animation for rotating the album art */
  }

  .listening-text p {
    margin: 0;
    color: #e0e0e0;
    font-size: 14px;
    line-height: 1.4;
  }

  .listening-text p:first-child {
    font-weight: bold;
    /* Make the song title bold */
  }

  /* Animation for rotating the album art */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {}
 .artists-container h2 {
   text-align: center;
   font-size: 1.5em;
   color: #e0e0e0;
   margin-bottom: 5px;
   margin-top: 5px;
 }

 /* Playlist items */
 .artists-container ol {
   padding-inline-start: 20px;
 }

 .list-item {
   align-items: center;
   margin-bottom: 10px;
   color: #e0e0e0;
 }

 .artist-info p {
   margin: 0px;
 }

 .artist-image {
   width: 60px;
   height: 60px;
   margin-right: 10px;
   border-radius: 5px;
 }
/* For the track text next to the image */
.tracks-container h2 {
  text-align: center;
  font-size: 1.5em;
  color: #e0e0e0;
  margin-bottom: 5px;
  margin-top: 5px;
}

.track-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.track-link {
  display: flex;
  align-items: center;
}

.tracks-container ol {
  padding-inline-start: 20px;
}

.list-item {
  display: flex;
  align-items: left;
  margin-bottom: 10px;
  color: #e0e0e0;
}

.track-image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 5px;
}

.track-text p {
  margin: 0;
  color: #e0e0e0;
  font-size: 14px;
  line-height: 1.4;
}

.track-name {
  font-weight: bold;
}
.genres-container h2 {
    text-align: center;
    font-size: 1.5em;
    color: #e0e0e0;
    margin-bottom: 5px;
    margin-top: 5px;
}

/* Playlist items */
.genres-container ol {
    padding-inline-start: 20px;
}

.list-item {
    align-items: center;
    margin-bottom: 10px;
    color: #e0e0e0;
}

.genre-name {

    font-size: 24px;
}

.genre-info p {
    margin: 0px;
}

.genre-image {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 5px;
}
.genre-stream {
    width: 100%;
    height: 100%;
    cursor: grab;
    /* Change cursor to grab (pan) icon when hovering */
}

/* Optional: Change to 'grabbing' icon when the user is actively dragging */
.genre-stream:active {
    cursor: grabbing;
}

/* Headings in each section */
.genre-stream h2 {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 5px;
    margin-top: 5px;
}
.bar-chart-container {
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.bar {
    fill: rgb(207, 31, 0);
}

.bar:hover {
    fill: rgb(207, 31, 0);
}

.label {
    font-size: 12px;
    fill: white;
}

.bar-chart-container svg {
    width: 100%;
    height: auto;
}

.bar-chart-container h2 {
    text-align: center;
}

.bar-chart-container p {
    text-align: center;
}
/* Overall Layout */
.dashboard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: #18191a;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Content Layout */
.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px auto;
  padding: 0 20px;
}

/* Container Layouts */
/* Container Layouts */
.metrics-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
  padding-bottom: 0;
  padding-top: 0;
}

.user-container,
.spin-time,
.chart-container,
.lists-container {
  background-color: #242526;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.user-container,
.spin-time {
  width: 100%;
  margin: 0;
  padding-top: 0;
}

.chart-container {
  width: 60%;
  max-width: 1500px;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: visible;
}

.lists-container {
  width: 20%;
  height: 87vh;
}


.metrics-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding-top: 0;
}

.user-container,
.spin-time {
  width: 100%;
  margin: 0;
  padding-top: 0;
}

.chart-container {
  width: 60%;
  max-width: 1500px;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: visible;
}

.lists-container {
  width: 20%;
  height: 87vh;
}

/* Header and Button Styling */
.header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  position: sticky;
}

.header-buttons button {
  z-index: 10;
}

.header-buttons h3 {
  text-align: center;
  font-size: 1.75em;
  color: #b9b9b9;
  margin: 5px 0;
}

.header-buttons img {
  height: 20px;
  width: 20px;
}

/* Button Styles */
.lists-container button,
.chart-container button {
  background-color: #242526;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #474747;
}

button:focus {
  outline: none;
}

/* Scrollbar Styling */
.lists-container::-webkit-scrollbar {
  width: 8px;
}

.lists-container::-webkit-scrollbar-track {
  background: #242526;
}

.lists-container::-webkit-scrollbar-thumb {
  background-color: #474747;
  border-radius: 10px;
  border: 2px solid #242526;
}

.lists-container {
  scrollbar-width: thin;
  scrollbar-color: #474747 #242526;
}

/* Animation Classes */
.fade-enter,
.slide-left-enter,
.slide-right-enter {
  opacity: 1;
}

.fade-enter,
.slide-left-enter,
.slide-right-enter {
  transform: translateX(100%);
}

.fade-enter-active,
.slide-left-enter-active,
.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit,
.slide-left-exit,
.slide-right-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-exit-active,
.slide-left-exit-active,
.slide-right-exit-active {
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

/* Mobile View */
@media (max-width: 1000px) {
  .content {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .lists-container,
  .metrics-container,
  .chart-container {
    width: 90%;
    max-width: 100%;
  }

  .chart-container {
    order: 2;
  }

  .lists-container {
    overflow-y: visible;
    order: 3;
    height: auto;
    max-height: 100%;
  }

  .metrics-container {
    order: 1;
  }
}

/* Desktop View - Sticky Sidebar */
@media (min-width: 1001px) {
  .lists-container {
    overflow-y: auto;
    position: sticky;
    top: 0;
  }
}
.spin-time-container {
    justify-content: center;
}

.spin-time-container h1 {

    font-size: 3em;
    text-align: center;
}

.navbar {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Push items to the far left and right */
  background-color: #242526;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin-bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  border: 2px;
  border-color: #dedede;
}

.auth-button {
  background-color: #8a8a8a;
  color: #000000;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: auto; /* Pushes the button to the left */
}

.auth-button:hover {
  background-color: #e0e0e0;
}

.auth-button:focus {
  outline: none;
}

.spin-puppy-logo {
  width: 30px;
  height: 30px;
}

.navbar-links {
  display: flex;
  margin: auto 0px;
  text-decoration: none;
  color: #cfcfcf; /* Text color */
  font-weight: bold;
}

.navbar-links:hover {
  text-decoration: none;
}

.navbar-links h3 {
  margin: 0;
  margin-left: 10px;
  margin-top: 2px;
}

@media (max-width: 350px) {
  .navbar {
    padding: 15px 10px;
    width: 100vw;
  }

  .navbar-links {
    flex-direction: column;
    width: 100%;
  }

  .navbar-links li {
    margin-left: 0;
    margin-top: 10px;
  }
}

/* src/App.css */


.app {
    font-family: Roboto;
    height: 100%;
}

h1,
h2,
h3,
h4,
li,
p {

    color: #e0e0e0;
}
