
.navbar {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Push items to the far left and right */
  background-color: #242526;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin-bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  border: 2px;
  border-color: #dedede;
}

.auth-button {
  background-color: #8a8a8a;
  color: #000000;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: auto; /* Pushes the button to the left */
}

.auth-button:hover {
  background-color: #e0e0e0;
}

.auth-button:focus {
  outline: none;
}

.spin-puppy-logo {
  width: 30px;
  height: 30px;
}

.navbar-links {
  display: flex;
  margin: auto 0px;
  text-decoration: none;
  color: #cfcfcf; /* Text color */
  font-weight: bold;
}

.navbar-links:hover {
  text-decoration: none;
}

.navbar-links h3 {
  margin: 0;
  margin-left: 10px;
  margin-top: 2px;
}

@media (max-width: 350px) {
  .navbar {
    padding: 15px 10px;
    width: 100vw;
  }

  .navbar-links {
    flex-direction: column;
    width: 100%;
  }

  .navbar-links li {
    margin-left: 0;
    margin-top: 10px;
  }
}
