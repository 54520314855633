.genre-stream {
    width: 100%;
    height: 100%;
    cursor: grab;
    /* Change cursor to grab (pan) icon when hovering */
}

/* Optional: Change to 'grabbing' icon when the user is actively dragging */
.genre-stream:active {
    cursor: grabbing;
}

/* Headings in each section */
.genre-stream h2 {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 5px;
    margin-top: 5px;
}