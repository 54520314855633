body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #18191a;
}

.resume-container {
  max-width: 95%;
  /* Ensure full width */
  width: 900px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 30px;
  padding-top: 10px;
  margin-top: 15px;
  background-color: #d6d6d6;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  /* Include padding in width */
}

.resume-container p,
.resume-container h1,
.resume-container h2,
.resume-container h3,
.resume-container h4,
.resume-container li {
  color: black;
}



header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.titles {
  text-align: left;
  flex: 2;
  /* Allow titles to take up remaining space */
}

.socials {
  text-align: center;
  flex: 1;
  /* Allow socials to take up remaining space */

}

.socials img {
  height: 50px;
  width: 50px;
  margin: 7px;
}

.git-logo {
  margin-left: 0px;
}



header h1 {
  margin-bottom: 0;
  font-size: 2em;
}

header p {
  margin-top: 5px;
  color: gray;
}

.resume-section {
  margin-bottom: 30px;
}

.resume-section h2 {
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
  margin-bottom: 15px;
  font-size: 1.5em;
  cursor: pointer;
  color: solid #333;
  display: flex;
  align-items: center;
}

.resume-section h2:hover {
  text-decoration: underline;
}

.resume-section h2 .fa-icon {
  margin-left: 8px;
}

.section-icon {
  font-size: 16px;
  margin-left: 8px;
}

.resume-item {
  margin-bottom: 20px;
}

.resume-item h3 {
  margin-bottom: 5px;
  font-size: 1.3em;
  display: flex;
  align-items: center;
}

.resume-item h3 img {
  margin-right: 10px;
}

.resume-item p {
  margin-bottom: 5px;
  color: gray;
  font-size: 0.9em;
}

.skills-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 15px;
}

.skills-list li {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  background: #eee;
  padding: 5px 10px;
  border-radius: 5px;
}

.project-title {
  margin-bottom: 0px;
  text-decoration: underline;
}

.project-info {
  margin-top: 5px;
}

a {
  color: #1a0dab;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.linkedin-logo {
  width: 60px;
  height: 60px;
  vertical-align: middle;
}

.git-logo {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.company-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  vertical-align: middle;
}

/* Responsive Styles */
@media (max-width: 768px) {


  .titles h1 {
    font-size: 1.5em;
  }

  .titles h2 {
    font-size: 1.3em;
  }

  .resume-item h3 {
    font-size: 1.2em;
  }

  .resume-item p {
    font-size: 0.85em;
  }

  .skills-list {
    font-size: 0.85em;
    margin-right: 8px;
  }

  .socials img {
    height: 40px;
    margin: 7px;
    width: 40px;
  }
}

@media (max-width: 480px) {
  header h1 {
    font-size: 1.5em;
  }

  header h2 {
    font-size: 1.2em;
  }

  header p {
    font-size: 0.9em;
  }

  .resume-section h2 {
    font-size: 1em;
  }

  .resume-item h3 {
    font-size: 1em;
  }

  .resume-item p {
    font-size: 0.8em;
  }

  .skills-list {
    font-size: 0.8em;
    margin-right: 5px;
    padding: 3px 8px;
  }

  .socials {
    display: flex;
    justify-content: center;
    /* Centers horizontally */
    align-items: center;
    /* Centers vertically */
    flex-direction: row;
    /* Makes sure the items stay in a row */
    flex: 1;
  }

  .socials img {
    height: 40px;
    margin: 5px;
    width: 40px;
    display: block;
  }
}

@media (max-width: 375px) {

  .titles h1 {
    font-size: 1em;
  }

  .titles h2 {
    font-size: .8em;
  }

  .socials img {
    height: 30px;
    width: 30px;
    margin: 5px;
  }
}

@media (max-width: 320px) {


  .titles h1 {
    font-size: 1em;
  }

  .titles h2 {
    font-size: .8em;
  }

  .socials img {
    height: 30px;
    width: 30px;
    margin: 3px;
  }
}